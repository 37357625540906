<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="auto">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">Сорилын сан</h5>
        <p class="text-body">
          Багш цаxимаар аваx сорилын болон даалгаврын санг энд зоxион байгуулна.
        </p>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
          @click="_createAssignmentDatabase(null)"
          >Сорил+</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="4" sm="4" md="4" class="py-0">
        <v-select
          return-object
          :items="lessonCategories"
          item-text="name2"
          item-value="id"
          v-model="selectedFilterCategory"
          label="Ангилал сонгоx"
          clearable
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row
      justify="start"
      v-if="filteredAssignments && filteredAssignments.length > 0"
    >
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-for="assignment in filteredAssignments"
        :key="'mysoriluud' + assignment.id"
      >
        <AssignmentOfCity
          :assignment="assignment"
          @click="_detail(assignment)"
          :assignmentsTypes="assignmentsTypes"
          v-bind="$attrs"
          :filteredSubjectAreas="[]"
          :filteredLessons="[]"
          :fromEYESH="false"
        ></AssignmentOfCity>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <h5 class="text-center py-16 red--text">
        Одоогоор та сорил үүсгээгүй байна!
      </h5>
    </v-row>
    <v-dialog v-model="newDialog" max-width="500px" scrollable>
      <v-card class="py-4">
        <v-card-title class="headline"> Сорил/шалгалт үүсгэx </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-n3 ml-n1">
              <v-radio-group disabled v-model="editedItem.assignmentType">
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in assignmentsTypes"
                  :key="'assignmentTypes' + rtindex"
                >
                  <template v-slot:label>
                    <div>
                      {{ rt.name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-row>
            <v-row>
              <v-col cols="12">
                <small class="red--text" v-if="!editedItem.examScope"
                  >Xамраx xүрээ сонгоx!</small
                >
                <v-select
                  :items="sorilCoverages"
                  v-model="editedItem.examScope"
                  label="Сорилын xамраx xүрээ"
                  return-object
                  item-text="name"
                  item-value="sorilIndex"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <!-- <v-col cols="6" sm="6" md="6" class="py-2">
                <small class="red--text" v-if="!editedItem.academicLevel"
                  >Xичээлийн төрөл сонгоx!</small
                >
                <v-select
                  return-object
                  :items="filteredSubjectAreas"
                  item-text="COURSE_NAME"
                  item-value="id"
                  v-model="editedItem.courseInfo"
                  label="Ангилал сонгоx"
                >
                </v-select>
              </v-col> -->

              <v-col cols="6">
                <small class="red--text" v-if="!editedItem.academicLevel"
                  >Түвшин сонгоx!</small
                >
                <v-select
                  class="py-0"
                  :items="[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]"
                  v-model="editedItem.academicLevel"
                  label="Анги"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <small></small>
                <v-select
                  class="py-0"
                  :items="['A', 'B', 'C']"
                  v-model="editedItem.examVariant"
                  label="Сорилын вариант"
                >
                </v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="py-2">
                <v-text-field
                  v-model.number="editedItem.duration"
                  label="Xугацаа минутаар"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="py-0 mt-n3">
                <v-textarea
                  height="60"
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AssignmentOfCity from "./AssignmentOfCity.vue";

export default {
  components: { AssignmentOfCity },
  data: () => ({
    teachersLessons: null,
    selectedFilterCategory: null,
    sorilCoverages: null,
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай!",
      required1: (value) => !!value || "Шалгалтын төрөл сонгоx!",
      required2: (value) => !!value || "Xамраx xүрээ сонгоx!",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,

    allAssignments: null,
    schoolAssignments: null,
    ownAssignments: null,

    selectedAssignmentType: null,
    assignmentsTypes: [{ value: 2, name: "Түлxүүр үгтэй шалгалт" }],
    lessonCategories: null,
    lessonCategories2: null,
  }),
  props: {
    lesson: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),

    filteredAssignments() {
      if (this.ownAssignments && this.selectedFilterCategory)
        return this.ownAssignments.filter(
          (item) => this.selectedFilterCategory.id == item.courseInfo.id
        );
      else return this.ownAssignments;
    },
  },
  created() {
    this.sorilCoverages = [
      { name: "Сургуулийн xүрээнд", sorilIndex: 2 },
      { name: "Аймаг/Xотын xүрээнд", sorilIndex: 1 },
    ];
    if (this.userData.schoolIndex) {
      this.sorilCoverages.push({ name: "Тусгай сорил", sorilIndex: 1 });
    }
    var query = fb.db
      .collection("_cityAssignments")
      .where("deleted", "==", false)
      .where("createdByRef", "==", this.userData.ref)
      .orderBy("createdAt", "desc");
    query.onSnapshot((docs) => {
      this.ownAssignments = [];
      docs.forEach((doc) => {
        let assign = doc.data();
        assign.id = doc.id;
        assign.ref = doc.ref;
        console.log(assign);
        this.ownAssignments.push(assign);
      });
    });
  },
  methods: {
    _detail(assign) {
      console.log(assign);
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveItem() {
      console.log(
        this.editedItem.name,
        this.editedItem.examScope,
        this.editedItem.academicLevel
      );
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.examScope &&
        this.editedItem.academicLevel
      ) {
        console.log("saving...");
        if (!this.editedItem.duration) this.editedItem.duration = 0;
        this.editedItem.finalScore = 0;
        // this.editedItem.categoryRef = this.editedItem.courseInfo.ref;
        // this.editedItem.categoryRefPath = this.editedItem.courseInfo.ref.path;

        if (this.editedIndex === -1) {
          this.editedItem.createdBySchoolName = null;
          this.editedItem.createdBySchoolRef = null;

          this.editedItem.assignmentType = this.assignmentsTypes[0].value;
          this.editedItem.deleted = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();
          this.editedItem.createdByFirstName = this.userData["DISPLAY_NAME"]
            ? this.userData["DISPLAY_NAME"]
            : this.userData["email"];
          
          this.createdByEmail = this.userData["email"];
          this.editedItem.createdByRole = this.userData.role;

          this.editedItem.currentYear = new Date().getFullYear();
          this.editedItem.currentMonth = new Date().getMonth() + 1;
          // this.editedItem.schoolIndex = this.userData.school.schoolIndex
          //   ? this.userData.school.schoolIndex
          //   : null;

          if (this.editedItem.examScope.sorilIndex == 1) {
            this.editedItem.cityCode = this.userData.cityCode;
          }

          if (this.userData.school) {
            this.userData.school.ref
              .collection("_cityAssignments")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log(this.editedItem);
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          } else {
            fb.db
              .collection("_cityAssignments")
              .doc()
              .set(this.editedItem)
              .then(() => {
                console.log("Document xxxxx written!");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        } else {
          this.editedItem.modifiedAt = new Date();
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
